<template>
  <div>
  <q-pull-to-refresh :handler="getChannels" refresh-icon="ion-sync" v-bind:release-message="$t('PTR.RELEASE')" v-bind:refresh-message="$t('PTR.REFRESH')" v-bind:pull-message="$t('PTR.PULL')" color="faded">
  <q-scroll-observable @scroll="scrolled"/>
  <q-page :class="{ 'flex flex-center full-height': !user.processed }">
    <div class="column items-center">

      <div v-if="!this.user.processed" class="text-center margin-auto-lr">
        <q-spinner-puff size="200px" class="loading-spinner loading-spinner-gold" style="color: #F4A724; display: block;"/>
      </div>
      <div v-else-if="!(authenticated || user.loggedIn)">
        <div class="margin-auto-lr" style="margin-top: 20px; width: 300px">
          <div class="limit-width-300">
            <p class="text-center full-width">
              <img :src="[`/statics/_demo/state.empty_heart`, anyDarkmode ? '@dark' : '', `.svg`].join('')"/>
            </p>
            <p class="on-bottom-xl text-gray text-center">
              Sign in to access your favorite services.
            </p>
            <p><q-btn class="full-width full-height text-family-brand text-weight-semibold uppercase" rounded :label="$t('GET_STARTED')" color="primary-gradient" text-color="white" @click="$router.push('/login')"></q-btn></p>
          </div>
        </div>
      </div>
      <div v-else>
        <!-- list of subscribed channels -->
        <p class="on-top-lgr text-center no-margin-bottom text-weight-semibold font-size-140p">Your Subscriptions</p>
        <p v-if="channels.length" class="text-center caption uppercase text-weight-semibold text-shallow no-margin font-size-80p">
          Your are subscribed to {{ channels.length }} services
        </p>
        <!-- Options -->
        <div class="margin-auto-lr text-center on-top-sm hidden">
          <q-btn icon="ion-information-circle-outline" rounded color="shallower" text-color="gray" @click.native="howToShow">
            <span class="on-right-sm text-system-brand text-weight-semibold">How to?</span>
          </q-btn>
        </div>
        <br>
        <q-list no-border highlight link style="width: 100vw; max-width: 700px">
          <transition v-for="(product, index) of channels" :key="`list-f-${index}`" appear enter-active-class="fadeInUp animated800">
            <q-item :to="`/channel/${product.uri}`" :data-index="index">
              <q-item-side>
                <q-icon :name="`ion-${type2IconName(product.data.business.types[0])}`" color="secondary" size="30px" style="width:30px"/>
              </q-item-side>
              <q-item-side left style="
              position: relative;
              margin-right: -42px;
              right: -14px;
              top: -11px;
              min-width: auto;
              width: 25px;
              height: 25px;
              text-align: center;
              z-index: 111;
              ">
                <q-item-tile v-if="product.verified">
                  <img src="/statics/_demo/checkmark.fill.seal_wing.svg" width="20" :alt="$t('VERIFIED_' + product.verified_status)" :title="$t('VERIFIED_' + product.verified_status)"
                  style="
                  position: relative;
                  top: 0;
                  left: 0;
                  border-radius: 1em;
                  padding: 2px;
                  width: 25px;
                  height: 25px;
                  border: 1px solid #fff;
                  background-color: #fff;
                  "/>
                </q-item-tile>
                <!--
                <q-item-tile v-if="index === 0 || index > 3" icon="ion-checkmark-circle" color="green"/>
                <q-item-tile v-if="index === 1" icon="ion-alert" color="attention"/>
                <q-item-tile v-if="index === 2" icon="ion-close-circle" color="protect"/>
                <q-item-tile v-if="index === 3" icon="ion-information-circle" color="shallow"/>
                -->
              </q-item-side>
              <q-item-side>
                <q-item-tile avatar>
                  <img v-if="getProductLogo(product) || getProductPhotoResource(product.data.media.photos.resources)" :src="getProductLogo(product) ? getProductLogo(product) : getProductPhotoResource(product.data.media.photos.resources)" :style="getProductLogo(product) ? getProductLogoStyle(product) : ''">
                  <img v-else style="background-color: #9B9B9B" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" class="no-photos-avatar"/>
                </q-item-tile>
              </q-item-side>
              <!-- <q-item-main :label="product.data.business.name" :sublabel="product.data.business.address.vicinity"/> -->
              <q-item-main>
                <q-item-tile label>
                  {{ product.data.business.name }}
                </q-item-tile>
                <q-item-tile sublabel>
                  <template v-if="v = getVenue(product)">
                    <q-chip dense color="shallower" text-color="core" :avatar="v.logo">{{ v.name }}</q-chip>
                  </template>
                  <template v-else>{{ product.data.business.address.vicinity }}</template>
                </q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-item-tile icon="ion-arrow-forward" color="black" />
              </q-item-side>
            </q-item>
          </transition>
        </q-list>
      </div>
    </div>

    <!-- MODAL: Get Started -->
    <q-modal id="dialogGetStarted" v-model="getStartedDialogShow" position="bottom" class="appLayer dialog-item">
      <q-modal-layout style="background-image: url(/statics/_demo/eyes.svg)">
        <q-toolbar slot="header" inverted>
          <q-toolbar-title>{{ $t('GET_STARTED') }}</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn :loading="dialogGetStartedAccepting" class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="getStarted">
                {{ $t('ACCEPT') }}
                <q-spinner-puff slot="loading" size="20px" />
              </q-btn>
            </q-card-main>
          </q-card>
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="tertiary-light" text-color="subinfo" rounded @click.native="getStartedDialogShow = false">
                {{ $t('DONE') }}
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding no-padding-top text-center">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <q-list class="text-family-brand" no-border link :dark="anyDarkmode">
            <p class="q-title layout-padding no-padding-top text-subinfo slideInDown" style="padding-bottom:20px">
              Please review our terms below before accepting and continuing.
            </p>
            <q-item item @click.native="openURL('https://letsbutterfly.com/terms')">
              <q-item-main :label="$t('DRAWER.ITEM.TERMS.L')" :sublabel="$t('DRAWER.ITEM.TERMS.D')" class="font-size-160p text-weight-semibold"/>
              <q-item-side class="text-center on-top-sm">
                <img src="/statics/_demo/square.and.arrow.up.fill_primary.svg" style="height:33px">
              </q-item-side>
            </q-item>
            <q-item item @click.native="openURL('https://letsbutterfly.com/privacy')">
              <q-item-main :label="$t('DRAWER.ITEM.PRIVACY.L')" :sublabel="$t('DRAWER.ITEM.PRIVACY.D')"  class="font-size-160p text-weight-semibold"/>
              <q-item-side class="text-center on-top-sm">
                <img src="/statics/_demo/square.and.arrow.up.fill_primary.svg" style="height:33px">
              </q-item-side>
            </q-item>
          </q-list>
        </div>
      </q-modal-layout>
    </q-modal>

    <!-- MODAL: How to -->
    <q-modal id="dialogHowTo" v-model="dialogHowToShow" position="bottom" class="appLayer dialog-item">
      <q-modal-layout>
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>How To</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top hidden">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="empower-light" text-color="empower" rounded @click="openURL(productFullURI)">
                {{ $t('FAQ') }}
              </q-btn>
            </q-card-main>
          </q-card>
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="howToHide">
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding no-padding-top">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <div class="content-wrapper">
            Find and scan the Wings QR Code at any participating business to subscribe to their live channel.
          </div>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Scan
    -->
    <q-modal id="dialogScan" v-model="dialogScanShow" class="appLayer dialog-item" position="bottom">
      <q-modal-layout>
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>{{ $t('WINGLET.SCAN.L') }}</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <!--
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="disabled margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="tertiary-light" text-color="subinfo" rounded @click.native="scanFlashToggle(); soundPlay('tap_disabled')">
                <q-icon size="2em" name="ion-flash"/>
              </q-btn>
            </q-card-main>
          </q-card>
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn :disable="scanner.noFrontCamera" :class="{ 'disabled': scanner.noFrontCamera}" class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="tertiary-light" text-color="subinfo" rounded @click.native="scanCameraToggle(); soundPlay('tap_disabled')">
                <q-icon size="2em" name="ion-reverse-camera"/>
              </q-btn>
            </q-card-main>
          </q-card>
          -->
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="scanHide(); soundPlay('tap'); soundFade('pinging', 0.2, 1);">
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding no-padding-top text-center text-family-brand">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <div id="scanDecodeContainer" class="overflow-hidden" style="height:50vh; border: 4px solid #3d4042; border-radius: 1em">
            <qrcode-stream v-if="dialogScanShow && dialogScanStreamShow" :camera="scanner.camera" :torch="scanner.torchActive" @decode="scanOnDecode" @init="scanOnInit" class="scanning"/>
          </div>
        </div>
      </q-modal-layout>
    </q-modal>

  </q-page>
  </q-pull-to-refresh>
  </div>
</template>

<script>
import LFooter from 'components/l-footer'
import { axiosLIO } from 'plugins/axios'
import { openURL } from 'quasar'

// QR Code Stream
import { QrcodeStream } from 'vue-qrcode-reader'

// Wings 2.0
import Wings from '../services/wings-2.js'

export default {
  name: 'PageIndex',
  components: {
    QrcodeStream,
    LFooter
  },
  props: [
    'auth',
    'authenticated',
    'lang',
    'ecosystem',
    'anyDarkmode',
    'wingletDialogTrigger',
    'accountDialogTrigger',
    'soundPlay',
    'soundFade',
    'modalAdapt'
  ],
  data () {
    return {
      showImages: true,
      getStartedDialogShow: false,
      dialogGetStartedAccepting: false,
      dialogHowToShow: false,
      dialogScanShow: false,
      dialogScanStreamShow: false,
      scanner: {
        camera: 'rear',
        noRearCamera: false,
        noFrontCamera: false,
        torchActive: false,
        torchNotSupported: false
      },
      user: {
        processed: false,
        loggedIn: false,
        metaData: null
      },
      channels: []
    }
  },
  mounted () {
    this.$emit('stopAjaxBar')
    document.querySelector('#appHeader').classList.add('no-border')
    document.querySelector('#appHeader').classList.add('no-shadow')
    // get auth details
    this.processUserAuth()
  },
  methods: {
    openURL,
    getEcosystemLabel (l) {
      return `E.${this.ecosystem_id_t}.${l}`
    },
    login () {
      this.auth.login()
    },
    logout () {
      axiosLIO.post('/logout').then((response) => {
        this.auth.logout()
      })
    },
    trigger () {

    },
    getVenue (product) {
      try {
        let placeId = product.data.place_id
        let venueId = Wings.venues.relations[placeId]
        if (venueId) {
          this.venue = Wings.venues.list[venueId]
          return this.venue
        }
      } catch (e) { }
      return false
    },
    getCurrentDateInText () {
      let currentDate = new Date()
      const months = ['JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE', 'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER']
      const days = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY']
      const monthName = months[currentDate.getMonth()]
      const dayName = days[currentDate.getDay()]
      const dayNumber = currentDate.getDate()
      return `${dayName}, ${monthName} ${dayNumber}`
    },
    type2IconName (type) {
      const type2IconTable = {
        'restaurant': 'restaurant',
        'food': 'restaurant',
        'meal_takeaway': 'restaurant',
        'meal_delivery': 'restaurant',
        'cafe': 'cafe',
        'laundry': 'shirt',
        'store': 'basket',
        'shopping_mall': 'cart',
        'supermarket': 'cart',
        'book_store': 'book',
        'bar': 'beer',
        'stadium': 'football',
        'hospital': 'medical',
        'veterinary_care': 'paw',
        'pet_store': 'paw',
        'electronics_store': 'desktop',
        'movie_theater': 'film',
        'florist': 'rose',
        'gym': 'fitness',
        'school': 'school',
        'university': 'school',
        'airport': 'airplane',
        'hair_care': 'cut',
        'beauty_salon': 'cut',
        'art_gallery': 'easel',
        'car_repair': 'car',
        'car_dealer': 'car',
        'parking': 'car',
        'gas_station': 'car',
        'drugstore': 'medkit',
        'pharmacy': 'medkit',
        'police': 'medal',
        'bank': 'card',
        'atm': 'cash',
        'home_goods_store': 'pricetags',
        'hardware_store': 'construct',
        'place_of_worship': 'heart-half',
        'mosque': 'heart-half',
        'church': 'heart-half',
        'synagogue': 'heart-half'
      }
      return type2IconTable[type] || 'business'
    },
    processChannels () {
      console.log(':: processChannels')
      // check if we have data already
      let storedChannels = this.$store.state.app.channels
      // if stored, load from cache
      if (storedChannels.list.length) {
        this.channels = storedChannels.list
        this.channelsGroup = storedChannels.group
        this.user.processed = true
      } else {
        this.getChannels(() => {
          this.user.processed = true
        })
      }
    },
    async processUserAuth () {
      console.log(':: processUserAuth')
      // we need to check if the server session is valid (not Magic)
      this.user.processed = false
      if (this.authenticated) {
        this.user.loggedIn = true
        this.processChannels()
        return
      }
      this.user.processed = true
      // this.user.processed = false
      // const isLoggedIn = await this.$magic.user.isLoggedIn()
      // this.user.loggedIn = isLoggedIn
      // console.log(':: isLoggedIn: ', this.user.loggedIn)
      // if (isLoggedIn) {
      //   const userMetaData = await this.$magic.user.getMetadata()
      //   this.user.metaData = userMetaData
      //   // process channels
      //   this.processChannels()
      //   return
      // }
      // this.user.processed = true
    },
    getProductPhotoResource (resources) {
      if (!resources || resources.length === 0) {
        return null
      }
      let resource = resources[0]
      if (resource.photo_reference) {
        return `https://maps.googleapis.com/maps/api/place/photo?maxwidth=800&photoreference=${resource.photo_reference}&key=AIzaSyB3og1L9DJe2lM7q3HocbVdLM2Q6lDhPZY`
      } else if (resource.photo_source) {
        return resource.photo_source
      }
    },
    getProductLogo (product) {
      try {
        return product.data.media.logo.url
      } catch (e) {}
      // custom
      switch (product.data.business.name) {
        case 'Talia Apartments':
          return 'https://www.talia-apts.com/uploads/properties/logos/639x639G/17338/white-logo_talia.png?1602865205'
        case 'Zaroob Motor City':
          return 'https://eatopi-content.kitopiconnect.com/images/logos/3ed2d6db-b6bd-47bd-9607-a8b7fbc1c009/header/9xtonkii3qf'
        case 'Starbucks':
          return 'https://upload.wikimedia.org/wikipedia/en/thumb/d/d3/Starbucks_Corporation_Logo_2011.svg/1920px-Starbucks_Corporation_Logo_2011.svg.png'
        case 'Allo Beirut - Hessa Street':
          return 'https://cdn-gdhcp.nitrocdn.com/YdLvFIYNyGMsMgiMTKYStcgbqCdztZVD/assets/images/optimized/rev-d9c8d70/wp-content/uploads/2020/12/logo2.png'
        case 'Avalon Marlborough':
          return 'https://www.avaloncommunities.com/pf/resources/img/brand-logos/avalon.svg?d=79'
      }
      return false
    },
    getProductLogoStyle (product) {
      let logoStyle = {
        backgroundColor: 'white',
        borderRadius: '4px'
      }
      try {
        return {
          backgroundColor: product.data.media.logo.bg,
          borderRadius: (product.data.media.logo.shape === 'circle') ? '4px' : '0px'
        }
      } catch (e) {}
      switch (product.data.business.name) {
        case 'Talia Apartments':
        case 'Avalon Marlborough':
          logoStyle.backgroundColor = 'black'
          break
        case 'Allo Beirut - Hessa Street':
          logoStyle.borderColor = 'transparent'
          break
        case 'Starbucks':
          logoStyle.borderRadius = '44px'
          break
      }
      return logoStyle
    },
    howToShow () {
      this.toolbarShadowOnOverscrollClear()
      this.dialogHowToShow = true
    },
    howToHide () {
      this.dialogHowToShow = false
    },
    scanShow () {
      this.dialogScanShow = true
      this.toolbarShadowOnOverscrollClear()
      setTimeout(() => {
        this.dialogScanStreamShow = true
      }, 200)
    },
    scanHide () {
      this.dialogScanStreamShow = false
      setTimeout(() => {
        this.dialogScanShow = false
        this.toolbarShadowOnOverscrollClear()
      }, 200)
    },
    scanFlashToggle () { },
    scanCameraToggle () {
      switch (this.scanner.camera) {
        case 'front':
          this.scanner.camera = 'rear'
          break
        case 'rear':
          this.scanner.camera = 'front'
          break
      }
    },
    scanOnDecode (result) {
      console.log('Scanner Scan: ', result)
      const regex1 = /^https:\/\/ltsbtrf\.ly\/(.+)$/
      const regex2 = /^https:\/\/mywin\.gs\/(.+)$/
      const match = result.match(regex1) || result.match(regex2)
      if (match) {
        // we have a valid link
        const value = match[1]
        console.log('Scanner Match: ', match)
        console.log('Scanner Valid: ', value)
        window.open(result)
        setTimeout(() => {
          this.scanHide()
        }, 100)
      } else {
        console.log('Scanner InValid')
        setTimeout(() => {
          this.$q.notify({
            detail: 'Not Wings',
            color: 'white',
            textColor: 'value',
            message: 'Unrelated Code',
            position: 'top',
            timeout: 2000
          })
          this.soundPlay('notification')
        }, 1)
      }
    },
    async scanOnInit (promise) {
      try {
        const { capabilities } = await promise
        this.scanner.torchNotSupported = !capabilities.torch
      } catch (error) {
        // if (error.name === 'NotAllowedError') {
        //   this.error = "ERROR: you need to grant camera access permisson"
        // } else if (error.name === 'NotFoundError') {
        //   this.error = "ERROR: no camera on this device"
        // } else if (error.name === 'NotSupportedError') {
        //   this.error = "ERROR: secure context required (HTTPS, localhost)"
        // } else if (error.name === 'NotReadableError') {
        //   this.error = "ERROR: is the camera already in use?"
        // } else if (error.name === 'OverconstrainedError') {
        //   this.error = "ERROR: installed cameras are not suitable"
        // } else if (error.name === 'StreamApiNotSupportedError') {
        //   this.error = "ERROR: Stream API is not supported in this browser"
        // }
        const triedFrontCamera = this.scanner.camera === 'front'
        const triedRearCamera = this.scanner.camera === 'rear'
        const cameraMissingError = error.name === 'OverconstrainedError'
        
        // camera check
        if (triedRearCamera && cameraMissingError) {
          this.scanner.noRearCamera = true
        }
        if (triedFrontCamera && cameraMissingError) {
          this.scanner.noFrontCamera = true
        }

        // torch check
        console.error(error)
      }
    },
    getStarted () {
      this.dialogGetStartedAccepting = true
      setTimeout(() => {
        this.login()
      }, 800)
    },
    toolbarShadowOnOverscrollTarget () {
      let modalTarget = null
      document.querySelectorAll('.modal').forEach((o, i) => {
        if (o.clientHeight !== 0) modalTarget = o
      })
      return modalTarget
    },
    toolbarShadowOnOverscrollClear (timeout = 10) {
      setTimeout(() => {
        try {
          let modalTarget = this.toolbarShadowOnOverscrollTarget()
          if (modalTarget) {
            modalTarget.querySelector('.toolbar-overscroll-shadow').classList.remove('toolbar-overscroll-shadow-show')
          }
        } catch (e) {
          console.log(e)
        }
      }, timeout)
    },
    toolbarShadowOnOverscroll (scroll) {
      let modalTarget = this.toolbarShadowOnOverscrollTarget()
      if (modalTarget) {
        if (scroll.direction === 'down' && scroll.position >= 1) {
          modalTarget.querySelector('.toolbar-overscroll-shadow').classList.add('toolbar-overscroll-shadow-show')
        } else if (scroll.direction === 'up' && scroll.position <= 10) {
          modalTarget.querySelector('.toolbar-overscroll-shadow').classList.remove('toolbar-overscroll-shadow-show')
        }
      }
    },
    scrolled (scroll) {
      // console.log(scroll)
      if (scroll.direction === 'down' && scroll.position >= 1) {
        document.querySelector('#appHeader').classList.remove('no-shadow')
      } else if (scroll.direction === 'up' && scroll.position <= 10) {
        document.querySelector('#appHeader').classList.add('no-shadow')
      }
    },
    filterChannelsByLocation () {
      this.$q.notify({
        message: 'Servces based on your location.',
        detail: 'Coming Soon',
        color: 'white',
        textColor: 'value',
        position: 'top',
        icon: 'ion-pin',
        closeBtn: this.$t('OK')
      })
    },
    getChannels (done) {
      this.$emit('startAjaxBar')
      const _getChannels = this.getChannelsIO().then((response) => {
        this.$emit('stopAjaxBar')
        let _c = [], _channels = response.data.data.channels
        _channels.forEach((item, index) => {
          // decode the payload
          item.data = JSON.parse(item.payload)
          _c[index] = item
          // log [item]
          console.log(item)
        })
        this.channels = _c
        // update store
        this.$store.commit('app/updateChannels', { list: _c })
        setTimeout(() => done(), 400)
      }).catch(error => {
        // bad
        console.error(':error', error)
        this.$emit('stopAjaxBar')
        // end session
        // this.$q.notify({
        //   message: this.$t('SESSION.ENDED.L'),
        //   color: 'white',
        //   textColor: 'value',
        //   detail: this.$t('SESSION.ENDED.D'),
        //   position: 'top',
        //   timeout: 3000
        // })
        // logout
        this.$router.push('/logout?r=' + crypto.randomUUID().replace(/-/g, ''))
        done()
      })
      return _getChannels
    },
    getChannelsIO () {
      return axiosLIO.get('/channels')
    },
    processCardSelection (card) {
      if (typeof card !== 'object') {
        let id = parseInt(card)
        card = this.$refs[`product-card-${id}`].$el || this.$refs[`product-card-${id}`][0].$el
      }
      let uri = this.channels[card.dataset.index].uri
      console.log(card, card.dataset.index)
      console.log(this.channels[card.dataset.index].uri)
      this.$router.push(`/channel/${uri}`)
    },
    setCardIntent (obj, id, cb) {
      let card = this.$refs[`product-card-${id}`].$el || this.$refs[`product-card-${id}`][0].$el
      // ignore intent if card is disabled
      if (card.attributes.disabled) return
      // handle card
      if (obj.isFirst) {
        card.classList.add('intent')
      } else if (obj.isFinal) {
        if (card.classList.contains('intent')) {
          card.classList.remove('intent')
          if (Math.abs(obj.offset.x) < 50 && Math.abs(obj.offset.y) < 50) {
            // call handler
            setTimeout(function () {
              (cb)(card)
            }, 1)
          }
        }
      } else {
        if (card.classList.contains('intent')) {
          if (Math.abs(obj.offset.x) > 50 || Math.abs(obj.offset.y) > 50) {
            card.classList.remove('intent')
          }
        }
      }
    }
  }
}
</script>

<style lang="stylus">
.btn-main
  max-width 400px
  width 80vw
  font-size 140%

.brand-logo
  width 100px

#dialogItem, .dialog-item
  .q-modal-layout
    background-repeat no-repeat
    background-position 130% bottom
    background-color rgba(255, 255, 255, 0.96)
    background-blend-mode color
    background-size 50%
  .q-option-inner
    i
      font-size 160%

#dialogItem, .dialog-item
  .q-modal-layout
    background-repeat no-repeat
    background-position 130% bottom
    background-color rgba(255, 255, 255, 0.96)
    background-blend-mode color
    background-size 50%
  .q-option-inner
    i
      font-size 160%

// inherited from myhomes.vue (look into integrating into app styl)
.product-container
  .product-category
    margin-top 20px
    margin-bottom -44px
    font-family omnes-pro
    font-weight bolder
    font-size 41px
    color rgba(136, 136, 136, 0.2)
    &.product-category-child
      margin-top 22px
  .q-card
    width 300px
    transition all .2s ease-in-out
    .q-card-title
      font-weight 700
      img
        display: block
    .q-card-main
      &.verified
        min-height 55px
      .row
        height 40px
    .q-card-subtitle
      font-size 12px
      text-transform uppercase
    .q-chip-card
      position absolute
      top 11px
      right 11px
      padding 0 8px
      border-radius 1rem
      border 1px solid alpha(white, 0.2)
  .intent
    transform scale(0.95)
    box-shadow 0rem -0rem 2rem 0rem rgba(0,0,0,0.2) !important
  .open
    transform scale(8)
    background-color #fff
    z-index 1000
    position relative
    transition all .4s ease-in
    .q-card-container
      visibility hidden
  .bigger
    width 448px
  @media only screen and (max-device-width: $breakpoint-xs-max)
    .q-card
      width 86vw

.scrolling-wrapper-flexbox
  display flex
  flex-wrap nowrap
  overflow-x auto
  .q-card
    flex 0 0 auto
    margin-right 10px !important
    &:last-child
      margin-right 40px !important

.scrolling-wrapper-flexbox-row
  display flex
  flex-wrap wrap
  flex-direction row
  overflow-x auto
  .q-card
    flex 1 0 300px

body.touch
  .scrolling-wrapper-flexbox-row
    justify-content space-evenly
    .q-card
      flex 1 1 140px
      margin 4px

@media only screen and (max-device-width: $breakpoint-xs-max)
  body.touch
    .scrolling-wrapper-flexbox-row
      .q-card
        flex 1 1 120px

body.no-touch
  .scrolling-wrapper-flexbox-row
    // justify-content flex-start
    justify-content space-evenly
    .q-card
      // flex 0 1 240px
      flex 1 1 220px
      // flex 0 1 220px
      margin 8px
      margin-top 20px
  .scrolling-wrapper-flexbox-row-wide
    .q-card
      flex 1 0 340px
      border 2px solid red

.scrolling-wrapper-flexbox, .scrolling-wrapper-flexbox-row
  padding 20px
  // padding-left 2px
  // margin-top -20px
  margin-bottom 0px
  width 100%
  -webkit-overflow-scrolling touch
  overflow-scrolling touch
  &::-webkit-scrollbar
    display none

.dialog-grow
  .modal-content
    max-height 95vh !important
  &.dialog-grow-full
    .modal-content
      max-height 100vh !important
      border-top-left-radius 0 !important
      border-top-right-radius 0 !important
  &.no-title
    .q-layout-header
      .q-toolbar:first-child
        display none

// slide
.slide-enter-active, .slide-leave-active
  transition transform 0.5s ease

.slide-enter, .slide-leave-to
  transform translateX(-100%)

</style>
