var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "q-pull-to-refresh",
        {
          attrs: {
            handler: _vm.getChannels,
            "refresh-icon": "ion-sync",
            "release-message": _vm.$t("PTR.RELEASE"),
            "refresh-message": _vm.$t("PTR.REFRESH"),
            "pull-message": _vm.$t("PTR.PULL"),
            color: "faded"
          }
        },
        [
          _c("q-scroll-observable", { on: { scroll: _vm.scrolled } }),
          _c(
            "q-page",
            { class: { "flex flex-center full-height": !_vm.user.processed } },
            [
              _c("div", { staticClass: "column items-center" }, [
                !this.user.processed
                  ? _c(
                      "div",
                      { staticClass: "text-center margin-auto-lr" },
                      [
                        _c("q-spinner-puff", {
                          staticClass: "loading-spinner loading-spinner-gold",
                          staticStyle: { color: "#F4A724", display: "block" },
                          attrs: { size: "200px" }
                        })
                      ],
                      1
                    )
                  : !(_vm.authenticated || _vm.user.loggedIn)
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "margin-auto-lr",
                          staticStyle: { "margin-top": "20px", width: "300px" }
                        },
                        [
                          _c("div", { staticClass: "limit-width-300" }, [
                            _c("p", { staticClass: "text-center full-width" }, [
                              _c("img", {
                                attrs: {
                                  src: [
                                    "/statics/_demo/state.empty_heart",
                                    _vm.anyDarkmode ? "@dark" : "",
                                    ".svg"
                                  ].join("")
                                }
                              })
                            ]),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "on-bottom-xl text-gray text-center"
                              },
                              [
                                _vm._v(
                                  "\n            Sign in to access your favorite services.\n          "
                                )
                              ]
                            ),
                            _c(
                              "p",
                              [
                                _c("q-btn", {
                                  staticClass:
                                    "full-width full-height text-family-brand text-weight-semibold uppercase",
                                  attrs: {
                                    rounded: "",
                                    label: _vm.$t("GET_STARTED"),
                                    color: "primary-gradient",
                                    "text-color": "white"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.push("/login")
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ])
                  : _c(
                      "div",
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "on-top-lgr text-center no-margin-bottom text-weight-semibold font-size-140p"
                          },
                          [_vm._v("Your Subscriptions")]
                        ),
                        _vm.channels.length
                          ? _c(
                              "p",
                              {
                                staticClass:
                                  "text-center caption uppercase text-weight-semibold text-shallow no-margin font-size-80p"
                              },
                              [
                                _vm._v(
                                  "\n        Your are subscribed to " +
                                    _vm._s(_vm.channels.length) +
                                    " services\n      "
                                )
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass:
                              "margin-auto-lr text-center on-top-sm hidden"
                          },
                          [
                            _c(
                              "q-btn",
                              {
                                attrs: {
                                  icon: "ion-information-circle-outline",
                                  rounded: "",
                                  color: "shallower",
                                  "text-color": "gray"
                                },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.howToShow($event)
                                  }
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "on-right-sm text-system-brand text-weight-semibold"
                                  },
                                  [_vm._v("How to?")]
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c("br"),
                        _c(
                          "q-list",
                          {
                            staticStyle: {
                              width: "100vw",
                              "max-width": "700px"
                            },
                            attrs: { "no-border": "", highlight: "", link: "" }
                          },
                          _vm._l(_vm.channels, function(product, index) {
                            return _c(
                              "transition",
                              {
                                key: "list-f-" + index,
                                attrs: {
                                  appear: "",
                                  "enter-active-class": "fadeInUp animated800"
                                }
                              },
                              [
                                _c(
                                  "q-item",
                                  {
                                    attrs: {
                                      to: "/channel/" + product.uri,
                                      "data-index": index
                                    }
                                  },
                                  [
                                    _c(
                                      "q-item-side",
                                      [
                                        _c("q-icon", {
                                          staticStyle: { width: "30px" },
                                          attrs: {
                                            name:
                                              "ion-" +
                                              _vm.type2IconName(
                                                product.data.business.types[0]
                                              ),
                                            color: "secondary",
                                            size: "30px"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-side",
                                      {
                                        staticStyle: {
                                          position: "relative",
                                          "margin-right": "-42px",
                                          right: "-14px",
                                          top: "-11px",
                                          "min-width": "auto",
                                          width: "25px",
                                          height: "25px",
                                          "text-align": "center",
                                          "z-index": "111"
                                        },
                                        attrs: { left: "" }
                                      },
                                      [
                                        product.verified
                                          ? _c("q-item-tile", [
                                              _c("img", {
                                                staticStyle: {
                                                  position: "relative",
                                                  top: "0",
                                                  left: "0",
                                                  "border-radius": "1em",
                                                  padding: "2px",
                                                  width: "25px",
                                                  height: "25px",
                                                  border: "1px solid #fff",
                                                  "background-color": "#fff"
                                                },
                                                attrs: {
                                                  src:
                                                    "/statics/_demo/checkmark.fill.seal_wing.svg",
                                                  width: "20",
                                                  alt: _vm.$t(
                                                    "VERIFIED_" +
                                                      product.verified_status
                                                  ),
                                                  title: _vm.$t(
                                                    "VERIFIED_" +
                                                      product.verified_status
                                                  )
                                                }
                                              })
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-side",
                                      [
                                        _c(
                                          "q-item-tile",
                                          { attrs: { avatar: "" } },
                                          [
                                            _vm.getProductLogo(product) ||
                                            _vm.getProductPhotoResource(
                                              product.data.media.photos
                                                .resources
                                            )
                                              ? _c("img", {
                                                  style: _vm.getProductLogo(
                                                    product
                                                  )
                                                    ? _vm.getProductLogoStyle(
                                                        product
                                                      )
                                                    : "",
                                                  attrs: {
                                                    src: _vm.getProductLogo(
                                                      product
                                                    )
                                                      ? _vm.getProductLogo(
                                                          product
                                                        )
                                                      : _vm.getProductPhotoResource(
                                                          product.data.media
                                                            .photos.resources
                                                        )
                                                  }
                                                })
                                              : _c("img", {
                                                  staticClass:
                                                    "no-photos-avatar",
                                                  staticStyle: {
                                                    "background-color":
                                                      "#9B9B9B"
                                                  },
                                                  attrs: {
                                                    src:
                                                      "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                                  }
                                                })
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-main",
                                      [
                                        _c(
                                          "q-item-tile",
                                          { attrs: { label: "" } },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  product.data.business.name
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "q-item-tile",
                                          { attrs: { sublabel: "" } },
                                          [
                                            (_vm.v = _vm.getVenue(product))
                                              ? [
                                                  _c(
                                                    "q-chip",
                                                    {
                                                      attrs: {
                                                        dense: "",
                                                        color: "shallower",
                                                        "text-color": "core",
                                                        avatar: _vm.v.logo
                                                      }
                                                    },
                                                    [_vm._v(_vm._s(_vm.v.name))]
                                                  )
                                                ]
                                              : [
                                                  _vm._v(
                                                    _vm._s(
                                                      product.data.business
                                                        .address.vicinity
                                                    )
                                                  )
                                                ]
                                          ],
                                          2
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-side",
                                      { attrs: { right: "" } },
                                      [
                                        _c("q-item-tile", {
                                          attrs: {
                                            icon: "ion-arrow-forward",
                                            color: "black"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
              ]),
              _c(
                "q-modal",
                {
                  staticClass: "appLayer dialog-item",
                  attrs: { id: "dialogGetStarted", position: "bottom" },
                  model: {
                    value: _vm.getStartedDialogShow,
                    callback: function($$v) {
                      _vm.getStartedDialogShow = $$v
                    },
                    expression: "getStartedDialogShow"
                  }
                },
                [
                  _c(
                    "q-modal-layout",
                    {
                      staticStyle: {
                        "background-image": "url(/statics/_demo/eyes.svg)"
                      }
                    },
                    [
                      _c(
                        "q-toolbar",
                        {
                          attrs: { slot: "header", inverted: "" },
                          slot: "header"
                        },
                        [
                          _c("q-toolbar-title", [
                            _vm._v(_vm._s(_vm.$t("GET_STARTED")))
                          ])
                        ],
                        1
                      ),
                      _c(
                        "q-toolbar",
                        {
                          staticClass: "toolbar-overscroll-shadow",
                          attrs: { slot: "header", inverted: "" },
                          slot: "header"
                        },
                        [
                          _c(
                            "q-card",
                            {
                              staticClass:
                                "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                            },
                            [
                              _c(
                                "q-card-main",
                                {
                                  staticClass:
                                    "column justify-center full-height"
                                },
                                [
                                  _c(
                                    "q-btn",
                                    {
                                      staticClass:
                                        "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                      attrs: {
                                        loading: _vm.dialogGetStartedAccepting,
                                        color: "primary-light",
                                        "text-color": "primary",
                                        rounded: ""
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.getStarted($event)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$t("ACCEPT")) +
                                          "\n              "
                                      ),
                                      _c("q-spinner-puff", {
                                        attrs: {
                                          slot: "loading",
                                          size: "20px"
                                        },
                                        slot: "loading"
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-card",
                            {
                              staticClass:
                                "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                            },
                            [
                              _c(
                                "q-card-main",
                                {
                                  staticClass:
                                    "column justify-center full-height"
                                },
                                [
                                  _c(
                                    "q-btn",
                                    {
                                      staticClass:
                                        "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                      attrs: {
                                        color: "tertiary-light",
                                        "text-color": "subinfo",
                                        rounded: ""
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          _vm.getStartedDialogShow = false
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$t("DONE")) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "layout-padding no-padding-top text-center"
                        },
                        [
                          _c("q-scroll-observable", {
                            on: { scroll: _vm.toolbarShadowOnOverscroll }
                          }),
                          _c(
                            "q-list",
                            {
                              staticClass: "text-family-brand",
                              attrs: {
                                "no-border": "",
                                link: "",
                                dark: _vm.anyDarkmode
                              }
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "q-title layout-padding no-padding-top text-subinfo slideInDown",
                                  staticStyle: { "padding-bottom": "20px" }
                                },
                                [
                                  _vm._v(
                                    "\n            Please review our terms below before accepting and continuing.\n          "
                                  )
                                ]
                              ),
                              _c(
                                "q-item",
                                {
                                  attrs: { item: "" },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.openURL(
                                        "https://letsbutterfly.com/terms"
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("q-item-main", {
                                    staticClass:
                                      "font-size-160p text-weight-semibold",
                                    attrs: {
                                      label: _vm.$t("DRAWER.ITEM.TERMS.L"),
                                      sublabel: _vm.$t("DRAWER.ITEM.TERMS.D")
                                    }
                                  }),
                                  _c(
                                    "q-item-side",
                                    { staticClass: "text-center on-top-sm" },
                                    [
                                      _c("img", {
                                        staticStyle: { height: "33px" },
                                        attrs: {
                                          src:
                                            "/statics/_demo/square.and.arrow.up.fill_primary.svg"
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item",
                                {
                                  attrs: { item: "" },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.openURL(
                                        "https://letsbutterfly.com/privacy"
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("q-item-main", {
                                    staticClass:
                                      "font-size-160p text-weight-semibold",
                                    attrs: {
                                      label: _vm.$t("DRAWER.ITEM.PRIVACY.L"),
                                      sublabel: _vm.$t("DRAWER.ITEM.PRIVACY.D")
                                    }
                                  }),
                                  _c(
                                    "q-item-side",
                                    { staticClass: "text-center on-top-sm" },
                                    [
                                      _c("img", {
                                        staticStyle: { height: "33px" },
                                        attrs: {
                                          src:
                                            "/statics/_demo/square.and.arrow.up.fill_primary.svg"
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "q-modal",
                {
                  staticClass: "appLayer dialog-item",
                  attrs: { id: "dialogHowTo", position: "bottom" },
                  model: {
                    value: _vm.dialogHowToShow,
                    callback: function($$v) {
                      _vm.dialogHowToShow = $$v
                    },
                    expression: "dialogHowToShow"
                  }
                },
                [
                  _c(
                    "q-modal-layout",
                    [
                      _c(
                        "q-toolbar",
                        {
                          directives: [
                            {
                              name: "touch-pan",
                              rawName: "v-touch-pan.vertical.prevent.stop",
                              value: _vm.modalAdapt,
                              expression: "modalAdapt",
                              modifiers: {
                                vertical: true,
                                prevent: true,
                                stop: true
                              }
                            }
                          ],
                          staticClass: "cursor-grab",
                          attrs: { slot: "header", inverted: "" },
                          slot: "header"
                        },
                        [_c("q-toolbar-title", [_vm._v("How To")])],
                        1
                      ),
                      _c(
                        "q-toolbar",
                        {
                          staticClass: "toolbar-overscroll-shadow",
                          attrs: { slot: "header", inverted: "" },
                          slot: "header"
                        },
                        [
                          _c(
                            "q-card",
                            {
                              staticClass:
                                "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top hidden"
                            },
                            [
                              _c(
                                "q-card-main",
                                {
                                  staticClass:
                                    "column justify-center full-height"
                                },
                                [
                                  _c(
                                    "q-btn",
                                    {
                                      staticClass:
                                        "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                      attrs: {
                                        color: "empower-light",
                                        "text-color": "empower",
                                        rounded: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.openURL(_vm.productFullURI)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$t("FAQ")) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-card",
                            {
                              staticClass:
                                "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                            },
                            [
                              _c(
                                "q-card-main",
                                {
                                  staticClass:
                                    "column justify-center full-height"
                                },
                                [
                                  _c(
                                    "q-btn",
                                    {
                                      staticClass:
                                        "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                      attrs: {
                                        color: "primary-light",
                                        "text-color": "primary",
                                        rounded: ""
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.howToHide($event)
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src:
                                            "/statics/_demo/" +
                                            (_vm.anyDarkmode
                                              ? "chevron.compact.down_white.svg"
                                              : "chevron.compact.down_primary.svg"),
                                          height: "10"
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "layout-padding no-padding-top" },
                        [
                          _c("q-scroll-observable", {
                            on: { scroll: _vm.toolbarShadowOnOverscroll }
                          }),
                          _c("div", { staticClass: "content-wrapper" }, [
                            _vm._v(
                              "\n          Find and scan the Wings QR Code at any participating business to subscribe to their live channel.\n        "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "q-modal",
                {
                  staticClass: "appLayer dialog-item",
                  attrs: { id: "dialogScan", position: "bottom" },
                  model: {
                    value: _vm.dialogScanShow,
                    callback: function($$v) {
                      _vm.dialogScanShow = $$v
                    },
                    expression: "dialogScanShow"
                  }
                },
                [
                  _c(
                    "q-modal-layout",
                    [
                      _c(
                        "q-toolbar",
                        {
                          directives: [
                            {
                              name: "touch-pan",
                              rawName: "v-touch-pan.vertical.prevent.stop",
                              value: _vm.modalAdapt,
                              expression: "modalAdapt",
                              modifiers: {
                                vertical: true,
                                prevent: true,
                                stop: true
                              }
                            }
                          ],
                          staticClass: "cursor-grab",
                          attrs: { slot: "header", inverted: "" },
                          slot: "header"
                        },
                        [
                          _c("q-toolbar-title", [
                            _vm._v(_vm._s(_vm.$t("WINGLET.SCAN.L")))
                          ])
                        ],
                        1
                      ),
                      _c(
                        "q-toolbar",
                        {
                          staticClass: "toolbar-overscroll-shadow",
                          attrs: { slot: "header", inverted: "" },
                          slot: "header"
                        },
                        [
                          _c(
                            "q-card",
                            {
                              staticClass:
                                "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                            },
                            [
                              _c(
                                "q-card-main",
                                {
                                  staticClass:
                                    "column justify-center full-height"
                                },
                                [
                                  _c(
                                    "q-btn",
                                    {
                                      staticClass:
                                        "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                      attrs: {
                                        color: "primary-light",
                                        "text-color": "primary",
                                        rounded: ""
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          _vm.scanHide()
                                          _vm.soundPlay("tap")
                                          _vm.soundFade("pinging", 0.2, 1)
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src:
                                            "/statics/_demo/" +
                                            (_vm.anyDarkmode
                                              ? "chevron.compact.down_white.svg"
                                              : "chevron.compact.down_primary.svg"),
                                          height: "10"
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "layout-padding no-padding-top text-center text-family-brand"
                        },
                        [
                          _c("q-scroll-observable", {
                            on: { scroll: _vm.toolbarShadowOnOverscroll }
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "overflow-hidden",
                              staticStyle: {
                                height: "50vh",
                                border: "4px solid #3d4042",
                                "border-radius": "1em"
                              },
                              attrs: { id: "scanDecodeContainer" }
                            },
                            [
                              _vm.dialogScanShow && _vm.dialogScanStreamShow
                                ? _c("qrcode-stream", {
                                    staticClass: "scanning",
                                    attrs: {
                                      camera: _vm.scanner.camera,
                                      torch: _vm.scanner.torchActive
                                    },
                                    on: {
                                      decode: _vm.scanOnDecode,
                                      init: _vm.scanOnInit
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }